<template>
  <div class="referrals d-flex flex-column">
    <div class="h-100 overflow-hidden">
      <CCard
        color="second-card-bg"
        class="shadow-none"
      >
        <CCardBody class="h-100 d-flex flex-column">
          <CInput
            v-model="search"
            :placeholder="$t('general.inputPlaceholder')"
          >
            <template #prepend>
              <CButton class="shadow-none" color="primary">
                <CIcon name="cil-search"/>
                {{ $t('general.search') }}
              </CButton>
            </template>
          </CInput>
          <div class="table-wrap">
            <table class="table mb-0">
              <thead>
              <tr>
                <th v-for="(field, i) in fields" :key="i" :class="field._classes">
                  {{ field.label }}
                </th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(item, i) in usersList" :key="i">
                <td
                  v-for="(field, i) in fields"
                  :key="'field-'+i"
                >
                  {{ item[field.key] }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <CPagination
            class="mt-2 pagination-custom"
            :class="{_disabled: loadingTable}"
            v-show="totalPages > 1"
            :activePage.sync="page"
            :pages="totalPages"
          />
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import User from "@/assets/js/User.class";

export default {
  name: "PageLkReferralsList",
  data() {
    return {
      loadingTable: false,
      usersList: [],
      page: 1,
      totalPages: null,
      search: '',
      fields: [
        {key: 'name', label: this.$t('lk.referrals.table.header.name')},
        {key: 'email', label: this.$t('lk.referrals.table.header.email')},
        {key: 'refBalance', label: this.$t('lk.referrals.table.header.refBalance')},
        {key: 'refCount', label: this.$t('lk.referrals.table.header.refCount')},
        {key: 'refIncome', label: this.$t('lk.referrals.table.header.refIncome')},
      ]
    }
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale
    }
  },
  created() {
    this.getUsers()
  },
  watch: {
    search(val) {
      if (typeof val !== 'string') return

      if (val.length >= 3) {
        this.page = 1
        const params = {}
        if (val) params.term = val

        debounce(this.getUsers, 300)(params)
      } else if (val.length === 0) {
        debounce(this.getUsers, 300)()
      }
    },
    page(val, pldVal) {
      if (val && val !== pldVal) {
        this.getUsers()
      }
    }
  },
  methods: {
    getUsers(params) {
      this.loadingTable = true

      const paramsLocal = {
        ...params,
        page: this.page,
      }

      if (this.search) {
        paramsLocal.term = this.search
      }
      User.getRefAll(paramsLocal)
        .then((res) => {
          const {data, status} = res
          if (status < 300) {
            this.usersList = data.data
            this.page = data.meta.current_page
            this.totalPages = data.meta.last_page
          }
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
  }
}
</script>

<style scoped>
.table-wrap {
  overflow: auto;
  width: 100%;
  flex-grow: 1;
}
</style>
